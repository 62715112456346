import React from 'react';
import styles from './login.module.css';
import Main from '../Main/Main';
import { useAuth } from '../../context/Context';
import { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

const Login = () => {
  const { currentUser, handleLogin } = useAuth();

  const providers = () => {
    return (
      <div className={styles.login}>
        <h1 className={styles.title}>Admin Dashboard DChess</h1>
        <div className={styles.login_buttons}>
          <button onClick={() => { handleLogin(GoogleAuthProvider) }}>Google</button>
          <button onClick={() => { handleLogin(FacebookAuthProvider) }}>Facebook</button>
        </div>
      </div>
    );
  }

  return (
    <>
      { !currentUser ? providers() : <Main /> }
    </>
  );
}

export default Login;
