import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { closeTicket, deleteMessage, getAllTickets, getTicketChat, sendMessage } from '../../../../services/api'
import TicketButton from './TicketButton'
import styles from './tickets.module.css'
import ClipLoader from 'react-spinners/ClipLoader'
import Search from '../Search/Search'

const Tickets = () => {
  const [tickets, setTickets] = useState('open')
  const [allTickets, setAllTickets] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loading1, setLoading1] = useState(true)
  const [chat, setChat] = useState(null)
  const [chatUser, setChatUser] = useState(null)
  const [message, setMessage] = useState(null)
  const [next, setNext] = useState(0)

  useEffect(() => {
    setLoading1(true)
    setLoading(true)
    getAllTickets(tickets)
      .then((data) => {
        setAllTickets(data)
        setChat(null)
      })
      .catch(() => {
        console.log('Error al cargar tickets')
      })
      .finally(() => {
        setLoading(false)
      })
    //eslint-disable-next-line
  }, [tickets, next])

  const handleChat = (user, idTicket) => {
    setChatUser(user)
    setLoading1(true)
    getTicketChat(idTicket)
      .then((data) => {
        setChat(data)
      })
      .catch(() => {
        console.log('Error al traer chat')
      })
      .finally(() => {
        setLoading1(false)
      })
  }

  const handleSendMessage = () => {
    const data = { ticketId: chat[0].support_ticket, message: message }
    sendMessage(data)
      .then(() => {
        document.getElementById('inputMessage').value = ''
        handleChat(chatUser, chat[0].support_ticket)
      })
      .catch(() => {
        console.log('Error al enviar mensaje')
      })
  }

  const handleCloseTicket = () => {
    let prompCloseChat = window.confirm('Sure you want to close this chat?')
    if (!prompCloseChat) return
    closeTicket(chat[0].support_ticket)
      .then(() => {
        setNext((prev) => prev + 1)
        setLoading1(true)
        setChat(null)
      })
      .catch(() => {
        console.log('Error al cerrar Ticket')
      })
  }

  const handleDelete = ({ id, support_ticket }) => {
    let prompDeleteMessage = window.confirm('Sure you want to delete this message?')
    if (!prompDeleteMessage) return
    deleteMessage(id)
      .then(() => {
        handleChat(chat[0].username, support_ticket)
      })
      .catch(() => {
        console.log('Error al eliminar mensaje')
      })
  }

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleSendMessage()
    }
  }

  return (
    <>
    <Search  />
      <div className={styles.container}>
        <div className={styles.ticketsContainer}>
          <div className={styles.buttonsTickets}>
            <button onClick={() => setTickets('open')} className={tickets === 'open' ? styles.active : styles.noActive}>
              Tickets Open
            </button>
            <button onClick={() => setTickets('close')} className={tickets === 'close' ? styles.active : styles.noActive}>
              Tickets Closed
            </button>
          </div>
          {!loading && Array.isArray(allTickets) ? (
            allTickets.map((ticket, index) => {
              return <TicketButton key={index} ticket={ticket} chat={chat} handleChat={handleChat} />
            })
          ) : (
            <div style={{ height: '10rem', display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
              <ClipLoader color={'#3A220B'} loading={loading} size={100} />
            </div>
          )}
        </div>
        <div className={styles.chatContainer}>
          {!loading1 && !loading && Array.isArray(chat)
            ? chat.map((message, index) => {
                let date = new Date(message.timestamp)
                return (
                  <div key={index} className={message.username === chatUser ? null : styles.admin}>
                    <div className={styles.messages}>
                      <h4 style={{ marginBlockStart: '0', marginBlockEnd: '0.3rem' }}>
                        {message.username === 'Monopoly' ? 'Rosquilla Puracrema' : message.username === 'Ceneka' ? 'Ceneka "El Dios"' : message.username}
                      </h4>
                      <p style={{ width: '30rem' }}>{message.message}</p>
                      <p style={{ marginBlockStart: '0.1rem', marginBlockEnd: '0', fontSize: '0.8rem' }}>{date.toLocaleString().replace('GMT', '')}</p>
                      <button
                        className={message.username === chatUser ? styles.deleteMessage : styles.deleteMessageAdmin}
                        onClick={() => handleDelete(message)}
                      >
                        Delete Message
                      </button>
                    </div>
                  </div>
                )
              })
            : null}
          {!loading1 ? (
            tickets === 'open' ? (
              <div className={styles.messageArea}>
                <input
                  type="text"
                  id="inputMessage"
                  placeholder="Write a message here"
                  className={styles.inputMessage}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <button className={styles.buttonSend} onClick={() => handleSendMessage()}>
                  SEND
                </button>
                <button className={styles.buttonClose} onClick={() => handleCloseTicket()}>
                  CLOSE TICKET
                </button>
              </div>
            ) : (
              <div>
                <h2 style={{ textAlign: 'center', color: '#ECB743' }}>TICKET CLOSED</h2>
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  )
}

export default Tickets
