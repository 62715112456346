import React from 'react'
import styles from './navbar.module.css'
import { useAuth } from '../../../../context/Context'
import { Link } from 'react-router-dom'

const NavBar = () => {
  const { currentUser, handleLogout } = useAuth()

  return (
    <header className={styles.navbar}>
      <nav className={styles.content}>
        <Link to="/" className={styles.home}>
          DChess Admin Dashboard
        </Link>
        <div className={styles.navigate}>
          <ul>
            <Link to='/tickets'>
              <li className={styles.navbarli}>Tickets</li>
            </Link>
            <Link to="/top-reported">
              <li className={styles.navbarli}>Top Reported</li>
            </Link>
            <Link to="/stats">
              <li /* className={} */>Stats</li>
            </Link>
          </ul>
        </div>
        <div className={styles.options}>
          <h4 className={styles.active_user}>{currentUser.email}</h4>
          <button className={styles.logout} onClick={() => handleLogout()}>
            EXIT
          </button>
        </div>
      </nav>
    </header>
  )
}

export default NavBar
