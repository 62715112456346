import React, { useEffect, useState } from 'react'
import styles from './statsInfo.module.css'

const StatsInfo = ({ stats }) => {
  const { deposited_king, generated_king, withdrawed_king, burned_king, sells } = stats
  const [totalBurned, setTotalBurned] = useState(0)
  const [totalGenerated, setTotalGenerated] = useState(0)
  let burnedKing = 0
  let generatedKing = 0

  useEffect(() => {
    setTotalBurned(burnedKing)
  }, [burnedKing])

  useEffect(() => {
      setTotalGenerated(generatedKing)
  }, [generatedKing])

  return (
    <div className={styles.container}>
      <div className={styles.king}>
        <div className={styles.kingSection}>
          <span style={{ fontSize: '1.3rem', color: '#ECB743' }}>KING Burned</span>
          <ul>
            {burned_king.map((a, index) => {
              { burnedKing += a.action === 'LOTTERY' ? a.total * 0.2 : a.total}
              return (
                <li key={index}>
                  {a.action.toLowerCase()}: {a.action === 'LOTTERY' ? (a.total * 0.2).toFixed(1) : a.total.toFixed(1)}
                </li>
              )
            })}
          </ul>
          <span style={{ fontSize: '1.3rem', color: '#ECB743' }}>Total Burned: {totalBurned.toFixed(1)}</span>
        </div>
        <div className={styles.kingSection}>
        <span style={{ fontSize: '1.3rem', color: '#ECB743' }}>KING Generated</span>
          <ul>
            {generated_king.map((a, index) => {
              { generatedKing += a.total }
              return (
                <li key={index}>
                  {a.action.toLowerCase()}: {a.total}
                </li>
              )
            })}
          </ul>
          <span style={{ fontSize: '1.3rem', color: '#ECB743' }}>Total Generated: {totalGenerated.toFixed(1)}</span>
        </div>
        <div className={styles.kingSection}>
        <span style={{ fontSize: '1.3rem', color: '#ECB743' }}>KING Movements</span>
            <ul>
                <li>Deposited: {deposited_king}</li>
                <li>Withdrawed: {withdrawed_king}</li>
            </ul>
        </div>
        <div className={styles.kingSection}>
        <span style={{ fontSize: '1.3rem', color: '#ECB743' }}>Sells</span>
          <ul>
            {sells.map((a, index) => {
              return (
                <li key={index}>
                  {a.action.toLowerCase()}: {a.amount}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default StatsInfo
