import React from 'react'
import { useState } from 'react'
import styles from './tickets.module.css'

const TicketButton = ({ ticket, chat, handleChat }) => {
  const { id, username, subject, reason, read_by_support } = ticket
  const [read, setRead] = useState(read_by_support === 1) //1 es leido, 0 es no leido

  return (
    <div
      className={chat === null ? styles.ticket : chat[0].support_ticket === id ? styles.ticketActive : styles.ticket}
      onClick={() => {
        handleChat(username, id)
        setRead(1)
      }}
    >
      <span className={styles.ticketNumber}>
        Ticket #{id} ({username})
      </span>
      <span className={styles.ticketInfo}>Subject: {subject}</span>
      <span className={styles.ticketInfo}>Reason: {reason === 1 ? 'Feedback' : reason === 2 ? 'Bug' : reason === 3 ? 'Suggestion' : 'Other'}</span>
      {!read ? <span className={styles.newMessage}></span> : null}
    </div>
  )
}

export default TicketButton
