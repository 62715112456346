import React, { useState, useEffect, useContext } from 'react';
import { auth } from '../services/firebase';
import { onAuthStateChanged, signInWithPopup } from 'firebase/auth';

const Context = React.createContext();

export const useAuth = () => {
  return useContext(Context);
}

export const ContextProvider = ({ children }) => {
  const [ currentUser, setCurrentUser ] = useState();
  const [ loading1, setLoading1 ] = useState(true);
  const [ , setError ] = useState(null);
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(true)

  const user = auth.currentUser;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading1(false);
    });
    return unsubscribe;
  }, []);

  const handleLogin = async (provider) => {
    try {
      await signInWithPopup(auth, new provider());
      setError(null);
    } catch (err) {
      setError(err);
    }
  }

  const handleLogout = () => {
    return auth.signOut();
  }

  return (
    <Context.Provider value={{
      user,
      currentUser,
      result,
      loading,
      setResult,
      setLoading,
      handleLogin,
      handleLogout,

    }}>
      { !loading1 && children }
    </Context.Provider>
  );
}
