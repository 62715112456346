import React, { useState } from 'react'
import styles from './reportTable.module.css'
import { getTopReports, getUserByUsername } from '../../../../services/api'
import Table from '../Table/Table'
import { ClipLoader } from 'react-spinners'
import { useQuery } from 'react-query'

const ReportTable = () => {
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(null)

  const { data, isLoading } = useQuery('reported', getTopReports)

  const handleUser = (user) => {
    getUserByUsername(user)
      .then((data) => {
        setResult(data)
      })
      .catch((err) => {
        console.log('Error al cargar userReported')
      })
      .finally(() => {
        setLoading(false)
        window.scrollTo(0, 0)
      })
  }

  return (
    <>
      {!isLoading ? (
        <>
          {result === null ? null : <Table user={result} loading={isLoading} />}
          <div className={styles.container}>
            {data?.map((user, index) => {
              return (
                <div key={index} className={styles.userContainer} onClick={() => handleUser(user.username)}>
                  <span>TOP {index + 1}</span>
                  <span className={styles.text}>Username: {user.username}</span>
                  <span className={styles.text}>Total Reports: {user.cantidad}</span>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '2rem', backgroundColor: '#3A220B' }}>
          <ClipLoader color={'#ECB743'} loading={loading} size={100} />
        </div>
      )}
    </>
  )
}

export default ReportTable
