import React from 'react'
import Tickets from '../Tickets/Tickets'
import styles from './dashboard.module.css'

const Dashboard = () => {
  return (
    <main className={styles.main}>
        <Tickets />
    </main>
  )
}

export default Dashboard
