import React, { useState } from 'react'
import { getStatsByDate } from '../../../../services/api'
import styles from './stats.module.css'
import StatsInfo from './StatsInfo'
import LiveStats from './LiveStats'

const Stats = () => {
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [error, setError] = useState(false)
  const [data, setData] = useState(null)

  const handleSearch = () => {
    setError(false)
    setData(null)
    if (!dateFrom) {
      setError('Enter "From:" date')
    } else if (!dateTo) {
      setError('Enter "To:" date')
    } else {
      let dates = { from: dateFrom, to: dateTo }
      getStatsByDate(dates)
        .then((data) => {
          if (data.error) {
            setError('No information in this range of dates')
            return
          }
          setData(data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <>
      {error && <h3 style={{ margin: '0 auto', textAlign: 'center', color: 'red', backgroundColor: '#3A220B', paddingTop: '1rem' }}>{error}</h3>}
      <div className={styles.container}>
        <div className={styles.inputs}>
          <label htmlFor="dateFrom">From: </label>
          <input type="date" id="dateFrom" onChange={(e) => setDateFrom(e.target.value)} />
        </div>
        <div className={styles.inputs}>
          <label htmlFor="dateTo">To: </label>
          <input type="date" id="dateTo" onChange={(e) => setDateTo(e.target.value)} />
        </div>
        <div className={styles.inputs}>
          <button onClick={() => handleSearch()}>Search</button>
        </div>
      </div>
      {data ? <StatsInfo stats={data} /> : <LiveStats />}
    </>
  )
}

export default Stats
