import axios from 'axios'
import { auth } from './firebase.js'
const host = process.env.REACT_APP_CLIENT_HOST

const options = async () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser.getIdToken()}`
    }
  }
}

const get = async (endpoint) => {
  const response = await axios.get(host + endpoint, await options())
  return response.data
}

const del = async (endpoint) => {
  const response = await axios.delete(host + endpoint, await options())
  return response.data
}

const post = async (url, data) => {
  const response = await axios.post(`${host}${url}`, data, await options())
  return response.data
}

const put = async (url, data = null) => {
  const response = await axios.put(`${host}${url}`, data, await options())
  return response.data
}

export const getUserByFirebaseId = async (uid) => {
  return await post('/user/uid', {
    uid: uid
  })
}

export const getUserById = async (id) => {
  return await post('/user/id', {
    id: id
  })
}

export const getTopReports = async () => get('/user/reports')

export const getLastGames = async (uid) => get('/user/games/' + uid)

export const getUserByWalletAddress = async (wallet) => {
  return await post('/user/wallet', {
    wallet: wallet
  })
}

export const getUserByEmail = async (email) => {
  return await post('/user/email', {
    email: email
  })
}

export const getUserByUsername = async (username) => {
  return await post('/user/username', {
    username: username
  })
}

export const setCoffee = async (id, quantity, admin) => {
  return await put('/user/coffee', {
    id: JSON.stringify(id),
    quantity: quantity,
    admin: admin
  })
}
export const setBan = async (status, uid, admin) => {
  return await put('/user/ban', {
    status: status,
    uid: uid,
    admin: admin
  })
}

export const getAllTickets = async (type) => get(`/user/getTickets/${type}`)

export const getTicketChat = async (idTicket) => get(`/user/getChat/${idTicket}`)

export const sendMessage = async (data) => post('/user/send', data)

export const closeTicket = async (idTicket) => get(`/user/closeTicket/${idTicket}`)

export const deleteMessage = async (idMessage) => del(`/user/message/${idMessage}`)

export const getStatsByDate = async (dates) => get(`/user/stats/${dates.from}/${dates.to}`)

export const getAllStats = async () => get(`/stats/all`)

export const getBoardsInfo = async (uid) => get(`/user/boards/${uid}`)
