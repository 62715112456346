import React from 'react'
import { useQuery } from 'react-query'
import styles from './livestats.module.css'
import { getAllStats } from '../../../../services/api.js'
import { ClipLoader } from 'react-spinners'

/*example 
{
    "Users": {
        "amount": 25599
    },
    "Matchs": {
        "amount": 757999
    },
    "MatchsLast24h": {
        "amount": 0
    },
    "BoardsSold": {
        "category": 0,
        "amount": 28277
    },
    "MarketplaceSales": {
        "king_moved": 814907,
        "amount": 27
    },
    "MarketplaceSalesLast24h": {
        "king_moved": null,
        "amount": 0
    }
}
*/

export default function LiveStats() {
  const { isLoading, data } = useQuery('allStats', () => getAllStats(), {
    refetchOnWindowFocus: false,
    refetchInterval: 1200000
  })

  if (isLoading)
    return (
      <div className={styles.livestats}>
        <ClipLoader color={'#ECB743'} loading={true} size={100} />
      </div>
    )

  return (
    <div className={styles.livestats}>
      <div className={styles.container}>
        <div className={styles.headerBigNumber}>{data?.Users.amount}</div>
        <div className={styles.label}>Users</div>
      </div>
      <div className={styles.container}>
        <div className={styles.headerBigNumber}>
          {data?.MatchsLast24h.amount} <span className={styles.last24}> / 24hs</span>
        </div>
        <div className={styles.label}>Total matchs: {data?.Matchs.amount}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.headerBigNumber}>
          {data?.MarketplaceSalesLast24h.amount} <span className={styles.last24}> / 24hs</span>
        </div>
        <div className={styles.label}>Total moves: {data?.MarketplaceSales.amount}</div>
        <div className={styles.label}>KING burnt: {data?.MarketplaceSales.king_moved * 0.03}</div>
        <div className={styles.label}>KING burnt 24hs: {data?.MarketplaceSalesLast24h.king_moved * 0.03}</div>
      </div>
    </div>
  )
}
