import './App.css'
import Login from './components/Login/Login'
import { ContextProvider } from './context/Context'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <ContextProvider>
          <QueryClientProvider client={queryClient}>
            <Login />
          </QueryClientProvider>
        </ContextProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
