import React, { useState, useEffect } from 'react'
import styles from './table.module.css'
import { setCoffee, setBan, getLastGames, getBoardsInfo } from '../../../../services/api'
import { useAuth } from '../../../../context/Context'

const Table = ({ user, loading }) => {
  const { currentUser } = useAuth()
  const [quantity, setQuantity] = useState(null)
  const [error, setError] = useState(null)
  const [player, setPlayer] = useState(user)
  const [visibleReports, setVisibleReports] = useState(false)
  const [visibleLastGames, setVisibleLastGames] = useState(false)
  const [visibleBoards, setVisibleBoards] = useState(false)
  const [lastGames, setLastGames] = useState(null)
  const [boards, setBoards] = useState(null)
  const [status, setStatus] = useState(null)
  const [close, setClose] = useState(true)

  useEffect(() => {
    setPlayer(user)
    setClose(false)
    setVisibleBoards(false)
    setVisibleLastGames(false)
    setVisibleReports(false)
    setBoards(null)
    setLastGames(null)
  }, [user])

  const handleCoffee = () => {
    if (quantity + player.coffee_tokens > 9) {
      setError('Cant add more than 9 Coffees')
    } else {
      setCoffee(user.id, quantity, currentUser.uid)
        .then((data) => {
          setPlayer(data)
          setQuantity(null)
          setError(null)
        })
        .catch((err) => {
          setError(err)
        })
    }
    document.getElementById('coffeInput').value = ''
  }

  const handleBans = () => {
    setError(null)

    if (!status || status === player.status) {
      return null
    } else {
      if (parseInt(status) === 0) {
        setBan('ACTIVE', user.firebase_id, currentUser.uid)
          .then((data) => {
            setPlayer(data)
            setStatus(null)
            setError(null)
          })
          .catch((err) => {
            setError(err.message)
          })
          .finally(()=>{
            document.getElementById('banInput').value = ''
          })
      } else if (parseInt(status) === 1) {
        setBan('BAN', user.firebase_id, currentUser.uid)
          .then((data) => {
            setPlayer(data)
            setStatus(null)
            setError(null)
          })
          .catch((err) => {
            setError(err)
          })
          .finally(()=>{
            document.getElementById('banInput').value = ''
          })
      } else {
        return null
      }
    }
  }

  const handleLastGames = () => {
    setVisibleLastGames(!visibleLastGames)
    if (visibleReports) {
      setVisibleReports(!visibleReports)
    }
    if (visibleBoards) {
      setVisibleBoards(!visibleBoards)
    }
    getLastGames(player.firebase_id)
      .then((data) => {
        setLastGames(data)
      })
      .catch((err) => {
        setError(err)
      })
  }

  const handleBoardsInfo = () => {
    setVisibleBoards(!visibleBoards)
    if (visibleReports) {
      setVisibleReports(!visibleReports)
    }
    if(visibleLastGames) {
      setVisibleLastGames(!visibleLastGames)
    }
    getBoardsInfo(player.firebase_id)
    .then((data) => {
      setBoards(data)
    })
    .catch((err) => {
      setError(err)
    })
  }

  if (!user) return null

  const reformatDate = (date, file = false, utc = false) => {
    let newDate = new Date(date)
    if (utc) return newDate.toUTCString()
    let day = newDate.getDate()
    let month = newDate.getMonth() + 1
    let year = newDate.getFullYear()
    let hours = newDate.getHours()
    let minutes = newDate.getMinutes()

    if (day < 10) day = '0' + day
    if (month < 10) month = '0' + month
    if (hours < 10) hours = '0' + hours
    if (minutes < 10) minutes = '0' + minutes

    return file ? day + '_' + month + '_' + year + '-' + hours + '_' + minutes : day + '/' + month + '/' + year + ' ' + hours + ':' + minutes
  }

  if (loading) {
    return <h4 style={{ margin: '0 auto', marginTop: '3rem' }}>Loading...</h4>
  }

  return (
    <>
    {!close ? 
      <div className={styles.table}>
        <div className={styles.close}>
        <button  onClick={()=>{
          setClose(true)
          setVisibleLastGames(false)
          setVisibleReports(false)
          }}>CLOSE</button>
        </div>
        <div className={styles.content}>
          <div className={styles.leftdata}>
            <h4 style={{ backgroundColor: '#ECB743', color: '#3A220B' }}>Information:</h4>
            <div className={styles.userinfo}>
              <div className={styles.rows}>
                <span>User: {player.username}</span>
                <span>ELO Rapid: {Math.round(player.rating_normal)} Normal / {Math.round(player.rating)} Ranked</span>
                <span>ELO Blitz: {Math.round(player.rating_normal_blitz)} Normal / {Math.round(player.rating_blitz)} Ranked </span>
                <span>Firebase UID: {player.firebase_id}</span>
                <span>
                  Wallet Address:{' '}
                  {player.wallet !== undefined ? (
                    <span>
                      <a href={`https://bscscan.com/address/${player.wallet}`} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#ECB743'}}>
                        {player.wallet}
                      </a>
                    </span>
                  ) : (
                    <span>*No wallet address*</span>
                    )}
                </span>
                <span>Status: {player.status}</span>
                <span>
                  Last Report: {player.reports.length === 0 ? 'No reports on record' : new Date(player.reports.at(-1).date).toUTCString().replace('GMT', '')}
                </span>
                <span>Total Reports: {player.reports.length}</span>
              </div>
            </div>
          </div>
          <div className={styles.rightdata}>
            <h4 style={{ backgroundColor: '#ECB743', color: '#3A220B' }}>Manage:</h4>
            {error && <h4 style={{ margin: '0 auto', color: 'red' }}>{error}</h4>}
            <div className={styles.userinfo}>
              <div className={styles.rows}>
                <div className={styles.coffee}>
                  <span>Coffee Tokens:</span>
                  <input
                    type="number"
                    maxLength="1"
                    placeholder={`Current: ${player.coffee_tokens}`}
                    style={{ paddingLeft: '.5rem' }}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                    id="coffeInput"
                    />
                  <button className={styles.managebutton} disabled={!quantity || parseInt(quantity) === user.coffee_tokens} onClick={() => handleCoffee()}>
                    Add
                  </button>
                </div>
                <div className={styles.coffee}>
                  <span>Ban User:</span>
                  <input
                    type="number"
                    maxLength="1"
                    placeholder="0: ACTIVE    |    1: BAN"
                    style={{ paddingLeft: '.5rem' }}
                    onChange={(e) => setStatus(e.target.value)}
                    id='banInput'
                    />
                  <button className={styles.managebutton} disabled={!status} onClick={() => handleBans()}>
                    Modify
                  </button>
                </div>
                <div className={styles.coffee}>
                  <span style={{ marginRight: '1rem' }}>Last Games</span>
                  <button className={styles.managebutton} onClick={() => handleLastGames()}>
                  {visibleLastGames ? 'Hide' : 'Show'}
                  </button>
                </div>
                <div className={styles.coffee}>
                  <span style={{ marginRight: '1rem' }}>Show Reports Details</span>
                  <button className={styles.managebutton} disabled={player.reports.length === 0} onClick={() => {
                    setVisibleReports(!visibleReports)
                    if(visibleLastGames) {
                      setVisibleLastGames(!visibleLastGames)
                    }
                    if (visibleBoards) {
                      setVisibleBoards(!visibleBoards)
                    }
                    }}>
                    {visibleReports ? 'Hide' : 'Show'}
                  </button>
                </div>
                <div className={styles.coffee}>
                  <span style={{ marginRight: '1rem' }}>Show Boards</span>
                  <button className={styles.managebutton} onClick={() => handleBoardsInfo()}>
                    {visibleBoards ? 'Hide' : 'Show'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      : null}
      {visibleReports && (
        <div className={styles.reports} style={{ marginBottom: '7rem' }}>
          {player.reports.map((e) => (
            <div className={styles.reportcard} key={e.fk_game}>
              <div className={styles.rheader}>
                <span>Date: {new Date(e.date).toUTCString().replace('GMT', '')}</span>
                <span>Pieces: {e.pieces === 'WHITE' ? 'Black' : 'White'}</span>
                <span>Result: {e.draw === 1 ? 'Draw' : e.win === 1 ? 'Lose' : 'Win'}</span>
                <span className={styles.clickable} onClick={() => navigator.clipboard.writeText(e.movements)}>
                  PGN Copy
                </span>
                <span
                  className={styles.clickable}
                  onClick={() => {
                    let a = document.createElement('a')
                    a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(e.movements)
                    a.download = 'dchess-' + reformatDate(e.date, true) + '-' + player.username + '.pgn'
                    a.click()
                  }}
                >
                  PGN Download
                </span>
              </div>
              <p style={{color: '#ECB743'}}>{e.comment === '' ? '*No report message*' : e.comment}</p>
            </div>
          ))}
        </div>
      )}
      {visibleLastGames && (
        <div className={styles.reports} style={{ marginBottom: '7rem' }}>
        {lastGames?.map((e, index) => (
          <div className={styles.reportcard} key={index}>
            <div className={styles.rheader}>
              <span>{index+1}</span>
              <span>Date: {new Date(e.date).toUTCString().replace('GMT', '')}</span>
              <span>Pieces: {e.pieces === 'WHITE' ? 'Black' : 'White'}</span>
              <span>Result: {e.result === 'WIN' ? 'Win' : e.result === 'LOSE' ? 'Lose' : 'Draw'}</span>
              <span>Type: {e.type}</span>
              <span className={styles.clickable} onClick={() => navigator.clipboard.writeText(e.movements)}>
                PGN Copy
              </span>
              <span
                className={styles.clickable}
                onClick={() => {
                  let a = document.createElement('a')
                  a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(e.movements)
                  a.download = 'dchess-' + reformatDate(e.date, true) + '-' + player.username + '.pgn'
                  a.click()
                }}
              >
                PGN Download
              </span>
            </div>
          </div>
        ))}
      </div>
      )}
      { visibleBoards && (
        <div className={styles.reports} style={{ marginBottom: '7rem' }}>
          {boards?.map((board, index) => {
            const date = new Date(board.cooldown).toLocaleString().split(',')[0]
            return (
            <div className={styles.reportcard} key={index}>
              <div className={styles.rheader}>
                  <span>Board {index+1}</span>
                  <span>id_blockchain: {board.id_blockchain !== null ? board.id_blockchain : 'null'}</span>
                  <span>Status: {board.active === 'true' ? 'active' : 'inactive'}</span>
                  <span>Rarity: {board.rarity}</span>
                  <span>Cooldown: {date}</span>
                  <span>Carrier: {board.carrier}</span>
              </div>
            </div>
            )
            })}
        </div>
      )

      }
    </>
  )
}

export default Table
