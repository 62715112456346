import React, { useState } from 'react'
import styles from './search.module.css'
import Table from '../Table/Table'
import { getUserByFirebaseId, getUserById, getUserByWalletAddress, getUserByEmail, getUserByUsername } from '../../../../services/api'

const Search = () => {
  const [search, setSearch] = useState(null)
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const handleSearch = async () => {
    setLoading(true)
    setResult(null)
    setError(null)
    document.getElementById('inputSearch').value = ''

    if (!search) {
      setError(true)
    } else {
      if (!isNaN(search) && search.length <= 6) {
        //verifica si es ID
        getUserById(search)
          .then((data) => {
            setResult(data)
            setError(null)
          })
          .catch((err) => {
            setError(err)
          })
          .finally(() => {
            setLoading(false)
          })
      } else if (search.length === 28) {
        //verifica si es UID
        getUserByFirebaseId(search)
          .then((data) => {
            setResult(data)
            setError(null)
          })
          .catch((err) => {
            setError(err)
          })
          .finally(() => {
            setLoading(false)
          })
      } else if (search.substr(0, 2) === '0x' && search.length === 42) {
        //verifica si es wallet
        getUserByWalletAddress(search)
          .then((data) => {
            setResult(data)
            setError(null)
          })
          .catch((err) => {
            setError(err)
          })
          .finally(() => {
            setLoading(false)
          })
      } else if (search.includes('@')) {
        //verifica si es email
        getUserByEmail(search)
          .then((data) => {
            setResult(data)
            setError(null)
          })
          .catch((err) => {
            setError(err)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        getUserByUsername(search)
          .then((data) => {
            // devuelve Username, si no existe devuelve error
            setResult(data)
            setError(null)
          })
          .catch((err) => {
            setError(err)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleSearch()
    }
  }

  return (
    <>
      <div className={styles.containerSearch}>
        {error && <h4 style={{ margin: '0 auto', textAlign: 'center', color: 'red' }}>User not found, enter valid user.</h4>}
        <div className={styles.container}>
          <div className={styles.searchbox}>
            <input
              className={styles.search}
              type="text"
              placeholder="ID, UID, Wallet Adress, Email, Username"
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={handleKeyPress}
              id="inputSearch"
            />
          </div>
          <button className={styles.search_button} onClick={() => handleSearch()}>
            Search
          </button>
        </div>
        <Table user={result} loading={loading} />
      </div>
    </>
  )
}

export default Search
