import React from 'react'
import NavBar from './content/NavBar/NavBar'
import Dashboard from './content/Dashboard/Dashboard'
import { Routes, Route } from 'react-router-dom'
import ReportTable from './content/ReportTable/ReportTable'
import Tickets from './content/Tickets/Tickets'
import Stats from './content/Stats/Stats'

const Main = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/top-reported" element={<ReportTable />} />
        <Route path='/tickets' element={<Tickets />} />
        <Route path='/stats' element={<Stats />} />
      </Routes>
    </>
  )
}

export default Main
